import React from "react"

const BgCurveSvgDesktop = props =>(
    <svg viewBox="0 0 264.9 768" {...props}>
        <g data-name="Layer 2">
            <path d="M250 0S330 438 0 768h265V0z" data-name="Layer 1" fill="#fff" />
        </g>
    </svg>
)

export default BgCurveSvgDesktop