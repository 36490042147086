import React from "react"
import "../../styles/_components/_forms/question_textarea.scss"

const  QuestionTextArea = ({ input, questionName, _className='',  name, onChange, required=false, error}) => {
    return (
        <div className={ `c-form_textarea ${error != undefined ? 'c-form-warning' : ''}` }>
            <label htmlFor={name}>{questionName}</label>
            <textarea className={`${_className}`} name={name} id={name} onChange={onChange} value={input} required={required} rows="3"></textarea>
            <span>{ error ? error : null}</span>
        </div>
    )
}

export default QuestionTextArea