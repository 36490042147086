import React from "react"
import "../../styles/_components/_forms/question_input.scss"

const  QuestionInput = ({ input, questionName, _className='', placeholder, name, onChange, required=false, type="text", maxlength="", error}) => {
    return (
        <div className={ `c-form_input ${error != undefined ? 'c-form-warning' : ''}` }>
            <label htmlFor={name}>{questionName}</label>
            <input className={`${_className}`} placeholder={placeholder} name={name} id={name} type={type}  maxLength={maxlength} onChange={onChange} value={input} required={required}/>
            <span>{ error ? error : null}</span>
        </div>
    )
}

export default QuestionInput