import React from "react"

function BgCurveSvgMobile(props) {
  return (
    <svg viewBox="0 0 360 108" {...props}>
      <g data-name="Layer 2">
        <path d="M0 105S198 129 360 0v108H0z" data-name="Layer 1" fill="#fff" />
      </g>
    </svg>
  )
}

export default BgCurveSvgMobile