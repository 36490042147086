import React from 'react'
import '../../styles/_components/_text/_header_text.scss'
// import PhoneContact from '../svg/commons/phone_contact'
// import Schedule from '../svg/commons/schedule'
import phoneImg from '../../../public/assets/images/common/phone.png'
import clockImg from '../../../public/assets/images/common/clock.png'
import '../../styles/_components/_contact/_header_contact.scss'

export const HeaderContact = ({phone, schedule}) => {
    return(
        <div className="c-header_contact">
            {phone &&
                <div className="c-header_contact_phone">
                    {/* <PhoneContact /> */}
                    <img src={phoneImg} alt="Teléfono" />
                    {/* <div>
                        <p>800 55 <span>CINSA</span> (24672)</p>
                    </div> */}
                    <div>
                       <p>800 55 <span>CINSA</span> (24672)</p>
                    </div>  
                </div>
            }
            {schedule &&
                <div className="c-header_contact_schedule">
                    {/* <Schedule /> */}
                    <img src={clockImg} alt="Horario" />
                    <div>
                        <p><span>Lunes</span> a <span>Sábado</span> de 8:00 a 20:00 hrs</p>
                        <p><span>Domingo</span> de 9:00 a 14:00 hrs</p>
                    </div>
                </div>
            }
        </div>
    )
}