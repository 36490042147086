
const validateEmail =  function(value) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value );
}

export default function validate(values) {
    let errors = {};

    //=======================================
    // validation for empty required fields
    //=======================================
    for(let key in values){
        const element = document.getElementById(key);
        if(element && element.hasAttribute('required') && !values[key].trim()){
            errors[key] = `*Campo obligatorio`;
        }
    }

    if (!validateEmail(values.email) && !errors.email) {
        errors.email = 'Formato de email invalido';
    }
    if (!/^[0-9]{10}$/.test(values.phone) && !errors.phone) {
            errors.phone = 'Formato de telefono invalido';
    }

    return errors;
  };

