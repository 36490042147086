import React from "react"

const BgCurveSvgTablet = props =>(
    <svg viewBox="0 0 768 217" {...props}>
        <g data-name="Layer 2">
            <path d="M0 210S403 266 768 0v217H0z" data-name="Layer 1" fill="#fff" />
        </g>
    </svg>
)

export default BgCurveSvgTablet