import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import '../styles/_components/_forms/forms.scss'
import { HeaderBgCurve } from "../components/headerBgCurve/header_bg_curve"
import { TitleStroke } from "../components/_titles/_header_title_stroke"
import { HeaderText } from "../components/_text/header_text"
import { HeaderContact } from "../components/_contact/_header_contact"
import { FormSubtitle } from "../components/_titles/_form_subtitle"
import QuestionInput from "../components/_forms/_question_input"
import QuestionTextArea from "../components/_forms/_question_textarea"
import Dropdown from "../components/_comparator/dropdown"
import ButtonSquareForms from "../components/buttons/_button_square_forms"
import useForm from "../hooks/useForm"
import validate from "../components/_form_validations/beADealersFormValidationRules"
import { navigate } from "gatsby"

import { usePrismModels } from '../hooks/use_prism-models';
import axios from "axios"


const Service  = () => {

	const [service, setService] = useState("Instalación / Mantenimiento")
	const [model, setModel] = useState(null)
	const models = usePrismModels().nodes;
	const [optionsModel, setOptionsModel] = useState(null);

	const typePhone = [
		{
			name:'Móvil'
		},
		{
			name:'Fijo'
		}
	]
	const optionsService = [
		{
			uid: 'c-mantenimiento',
			value: 'Instalación / Mantenimiento',
		},
		{
			uid: 'c-reporte-falla',
			value: 'Reporte de Falla'
		},
		{
			uid: 'c-asesoria-tecnica',
			value: 'Asesoría Técnica',
		},
		{
			uid: 'c-registro-garantia',
			value: 'Registro de Garantía',
		},
	]

	const optionsTipoEnergia = [
		{
			uid: 1,
			value:'Gas LP',
		},
		{
			uid: 2,
			value:'Gas Natural'
		},
		{
			uid: 3,
			value: 'Eléctrico'
		},
		{
			uid: 4,
			value: 'Solar'
		}
	]
	const myInputs = {
		servicio: optionsService[0],
		modelo: {
			uid: models[0].uid,
			value: models[0].data.name.text,
		},
		tipo_energia: optionsTipoEnergia[0],
		numero_serie: "",
		tienda_compra: "",
		fecha_compra: "",
		numero_ticket: "",
		fecha_expira: "",
		nombre: '',
		email: '',
		phone: '',
		calle: "",
    	num_ext: "",
    	num_int: "",
		colonia: '',
		ciudad: '',
		estado: '',
		codigo_postal: '',
		mensaje: '',
		marca: "Cinsa",
	}

	const [tipo_energia, setTipoEnergia] = useState(optionsTipoEnergia[0].value)
	const [send, setSend] = useState(true)
	const { inputs, handleInputChange, handleSubmit, errors } = useForm(myInputs, formValidCallback, validate, send )

	function handleServiceClick(item){
		setService(item)
		inputs.servicio = item
	}
	function handleModelClick(item){
		setModel(item)
		inputs.modelo = item
	}
	function handleTipoEnergiaClick(item){
		setTipoEnergia(item)
		inputs.tipo_energia= item
	}

	function formValidCallback (errorsForm){
		// console.log("errors form", errors)
		if(Object.keys(errorsForm).length > 0) return
		if(!send) return

		setSend(false) // valida que el formulario solo se envie una vez
		// console.log('Datos de servicio solicitado: ', {...inputs})
		axios.post(
			process.env.MAINTENANCE_ENDPOINT,
			{...inputs }
		)
		.then((response) => {
			// console.log("response", response)
			navigate('/enviado')
		})
		.catch(error => console.log("error", error))
	}

	useEffect(()=>{
		let modelsArray = [];
		models.forEach(item => {
			modelsArray = [
				...modelsArray,
				{
					uid: item.uid,
					value: item.data.name.text
				},
			]
		});
		setModel(modelsArray[0].value)
		setOptionsModel(modelsArray);
		document.querySelector('html').classList.add('html-form')

		return () => {
			document.querySelector('html').classList.remove('html-form')
		}
	},[])

  	return (
		<Layout>
			<HeaderBgCurve>
				<div className="c-header_content">
					<TitleStroke textStroke="Solicita" text="ayuda" />
					<HeaderText text="En Cinsa es muy importante brindarte una atención y servicio de la mejor calidad. Por eso ponemos a tu disposición personal capacitado que te brindará soporte técnico." />
					<HeaderContact phone={true} schedule={true} />
				</div>
			</HeaderBgCurve>
			<div className="c-form_container">
				<div className="c-form_mask_header"></div>
				<form noValidate className="c-form_content" onSubmit={ handleSubmit } autoComplete="off">
					<FormSubtitle text="Llena los campos" />
					<Dropdown isMaintenance={true} default_item={ service } items={ optionsService } handleItemClick={handleServiceClick}  label={true} questionName="¿En qué podemos ayudarte?" />
					{
						optionsModel && <Dropdown isMaintenance={true}  default_item={ model } items={ optionsModel } handleItemClick={handleModelClick}  label={true} questionName="Selecciona un modelo" />
					}
					<Dropdown isMaintenance={true} default_item={ tipo_energia } items={ optionsTipoEnergia } handleItemClick={handleTipoEnergiaClick}  label={true} questionName="Tipo de energía" />
					<QuestionInput onChange={handleInputChange} input={inputs.numero_serie} questionName="Número de serie" placeholder="328-x-324-7845-93170c" name="numero_serie" required={true} error={errors.numero_serie} />
					<QuestionInput onChange={handleInputChange} input={inputs.tienda_compra} questionName="Distribuidor (Tienda)" placeholder="Liverpool" name="tienda_compra" required={true} error={errors.tienda_compra} />
                    <QuestionInput onChange={handleInputChange} input={inputs.fecha_compra} questionName="Fecha de compra" placeholder="12/06/2020" name="fecha_compra" required={true} error={errors.fecha_compra} />
                    <QuestionInput onChange={handleInputChange} input={inputs.numero_ticket} questionName="Número de Ticket o Nota" placeholder="2855-9348-3574-5371" name="numero_ticket" required={true} error={errors.numero_ticket} />
					<QuestionInput onChange={handleInputChange} input={inputs.fecha_expira} questionName="La garantía vence" placeholder="12/06/2020" name="fecha_expira" required={true} error={errors.fecha_expira} />
					<FormSubtitle _className="c-subtitle_2" text="Datos personales" />
					<QuestionInput onChange={handleInputChange} input={inputs.nombre} questionName="Nombre" placeholder="Alex Villa Nueva" name="nombre" required={true} error={errors.nombre} />
					<QuestionInput onChange={handleInputChange} input={inputs.email} questionName="Email" placeholder="Cinsa@hotmail.com" name="email" type="email" required={true} error={errors.email} />
					<div className="c-form_group_radio">
						<QuestionInput onChange={handleInputChange} input={inputs.phone} _className="c-inputWtRadio c-input_small" questionName="Teléfono" placeholder="8441234567" name="phone" required={true} error={errors.phone} maxlength="10" />
					</div>
					<QuestionInput onChange={handleInputChange} input={inputs.calle} _className="c-input_small" questionName="Calle" placeholder="Miguel Hidalgo" name="calle" required={true} error={errors.calle} />
                    <QuestionInput onChange={handleInputChange} input={inputs.num_ext} _className="c-input_small" questionName="Número exterior" placeholder="1534" name="num_ext" required={true} error={errors.num_ext} />
                    <QuestionInput onChange={handleInputChange} input={inputs.num_int} _className="c-input_small" questionName="Número interior" placeholder="15" name="num_int" error={errors.num_int} />
					<QuestionInput onChange={handleInputChange} input={inputs.colonia} _className="c-input_small" questionName="Colonia" placeholder="Valle del Campestre" name="colonia" required={true} error={errors.colonia} />
					<QuestionInput onChange={handleInputChange} input={inputs.ciudad} _className="c-input_small" questionName="Ciudad" placeholder="Monterrey" name="ciudad" required={true} error={errors.ciudad} />
					<QuestionInput onChange={handleInputChange} input={inputs.estado} questionName="Estado" placeholder="Nuevo León" name="estado" required={true} error={errors.estado} />
					<QuestionInput onChange={handleInputChange} input={inputs.codigo_postal} questionName="Código Postal" placeholder="25000" name="codigo_postal" required={true} error={errors.codigo_postal} />
					<QuestionTextArea onChange={handleInputChange} input={inputs.mensaje} questionName="Escribe aquí tu comentario" name="mensaje" required={true} error={errors.mensaje} />
					<ButtonSquareForms text="Enviar" background="254, 190, 35" disabled={send ? false : true} />
				</form>
			</div>
		</Layout>
	)
}

export default Service