import React from "react"

const BgCurveSvg1024 = props => (
    <svg viewBox="0 0 184 768" {...props}>
        <g data-name="Layer 2">
            <path d="M147 0h37v768H0S281 406 147 0z" data-name="Layer 1" fill="#fff" />
        </g>
    </svg>
)

export default BgCurveSvg1024