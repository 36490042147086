import React, {useRef, useEffect, useState, Children} from 'react'
import BgCurveSvgMobile from '../svg/bgCurve/bg_curve_mobile'
import BgCurveSvgTablet from '../svg/bgCurve/bg_curve_tablet'
import BgCurveSvg1024 from '../svg/bgCurve/bg_curve_1024'
import BgCurveSvgDesktop from '../svg/bgCurve/bg_curve_desktop'
import {useWindowSize} from '../../hooks/use_window-size'
import '../../styles/_components/_header_bg_curve/_header_bg_curve.scss'

export const HeaderBgCurve = ({children, color_start='#2977bc', color_end='#2977bc', _className=""}) => {

    const background = useRef(null)
    const windowSize = useWindowSize()
    const [bgCurve, setBgCurve] = useState()

    useEffect(()=>{

        background.current.style.background = `linear-gradient(180deg, ${color_start} 0%, ${color_end} 100%)`

    },[])

    useEffect(()=>{
        if( windowSize.width < 768){
            setBgCurve(<BgCurveSvgMobile />)
        } else if(windowSize.width < 1024){
            setBgCurve(<BgCurveSvgTablet />)
        } else if(windowSize.width < 1366){
            setBgCurve(<BgCurveSvg1024 />)
        }else{
            setBgCurve(<BgCurveSvgDesktop />)
        }
    }, windowSize)

    return(
        <div ref={background} className={`c-header_bg ${_className}`}>
            {children}
            { bgCurve }
        </div>
    )
}