import { useState, useEffect } from 'react'

const useForm = (initialValues, callback, validate, send, terms, setTermsError) => {

    const [inputs, setInputs] = useState(initialValues)
    const [errors, setErrors] = useState({})
    const handleSubmit = (event) => {
        if(send === false) return
         
        if (event){
            event.preventDefault()
        }

        // validacion para el checkbox
        if(!terms && setTermsError){
            setTermsError(true)
        }

        setErrors(validate(inputs))
        callback(validate(inputs))
    }
    const handleInputChange = (event) => {
        event.persist()
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}))
    }

    useEffect(() =>{
        if(Object.keys(errors).length === 0){
            callback(validate(inputs))
        }
    }, [errors])

    return {
        handleSubmit,
        handleInputChange,
        inputs,
        errors
    }
}
export default useForm